$(document).ready(function () {
    /* =====  MOBILE MENU TOGGLE  ====== */
    $(".offCanvasToggler").click(function (event) {
        event.preventDefault();
        $(".off-canvas-nav").toggleClass("active");
        $(".fullscreen-overlay").toggleClass("active");
        $("html, body").toggleClass("no-scrolling");
    });

    /*======  SLIDERS  ======*/

    /*----------  ADVIESRAAD SLIDER  ----------*/

    $(".adviesraad-slider .slides").slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        prevArrow: $(".adviesraad-slider .slider-controls .prev-slide"),
        nextArrow: $(".adviesraad-slider .slider-controls .next-slide"),
        dots: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 991.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 5000
                }
            }
        ]
    });

    /*----------  CASES SLIDER  ----------*/

    $(".cases-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000
    });

    let caseHeight = $(".cases-slider .slick-track").height();
    $(".cases-slider .slick-slide").css("height", caseHeight + "px");

    /*----------  REFERENCES SLIDER  ----------*/

    $('.references-slider .slides').slick({
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: false,
        arrows: true,
        prevArrow: $('.references-slider .slider-controls .prev-slide'),
        nextArrow: $('.references-slider .slider-controls .next-slide'),
        autoplay: false,
        responsive: [
            {
                breakpoint: 1281,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }).on('setPosition', function (event, slick, currentSlide, nextSlide) {
        let referenceHeight = $('.references-slider .slick-track').height();
        $('.references-slider .slick-slider').css('height', referenceHeight + 'px');
    });

    /*======  UTILITY  ======*/

    /*----------  BLUE FILLER SERVICE ITEMS  ----------*/

    let voordelenHeight = $(".voordelen-block").outerHeight(true) + 30;
    $(".blue-filler").css("height", voordelenHeight - 100);
    $(".blue-filler").css("margin-top", (voordelenHeight * -1) / 2 - 60);

    $('.grijs').gray();

    $(window).resize(function () {
        $('.grijs').gray();
    });

    /*----------  TOOLTIPS  ----------*/
    $('[data-toggle="tooltip"]').tooltip()

    const numberOfItems = 9;

    /*----------  INFINITE ON LOAD BUTTON  ----------*/
    //Show 6 items at start
    $("[data-role=content-item]").slice(0, numberOfItems).show();
    //If there are less than 6 items don't show load more button
    if ($("[data-role=content-item]:hidden").length === 0) {
        $("#loadMore").remove();
    } else {
        $("#loadMore").on("click", function (e) {
            e.preventDefault();
            // On click show 6 more...
            $("[data-role=content-item]:hidden").slice(0, numberOfItems).slideDown();
            // Remove load more button when there are no more items
            if ($("[data-role=content-item]:hidden").length === 0) {
                $("#loadMore").remove();
            }
        });
    }

    /*----------  ADD THESE CLASSES TO IMG IN CMS EDITOR  ----------*/
    $(".cms p > img").each(function() {
        $(this).removeAttr('width', 'height');
        $(this).addClass('img-fluid py-4 align_center');
    });

    /*----------  ADD THIS CLASS TO H2 IN CMS EDITOR  ----------*/
    $(".cms h2").each(function() {
        $(this).addClass('mt-4');
    });

    /*----------  ADD THIS CLASS TO P IN CMS EDITOR IF IFRAME IS DETECTED  ----------*/
    $("p").has('iframe').addClass('iframe-box');

    $(".cms p > iframe").each(function() {
        $(this).removeAttr('width', 'height');
        $(this).addClass('responsive-iframe py-4');
    });

    /*----------  SCROLL TO BOTTOM BUTTON  ----------*/
    let scrolldown = $('#scrolldown');

    $(window).scroll(function(){
        /* hide on scroll */
        scrolldown.hide();
    });
    scrolldown.on('click', function() {
        var scrollTo = $(window).scrollTop() + 500;
        $(document).scrollTop(scrollTo);
    });
    /*----------  SCROLL TO TOP BUTTON  ----------*/
    let scrollup = $('#scrollup');
    let showAfter = 150;

    if ( $(this).scrollTop() < showAfter ) {
        /* also hide on page reload */
        scrollup.hide();
    }
    $(window).scroll(function(){
        // Show button after 100px
        if ( $(this).scrollTop() > showAfter ) {
            scrollup.show();
        }else{
            scrollup.hide();
        }
    });
    scrollup.on('click', function() {
        $(window).scrollTop(0);
    });

    /*----------  ADD CAROUSEL TO CMS PHOTO ALBUM  ----------*/

    /*
    * Don't forget to add the following code in the CMS source code,
    * under the photo album widget.
    *
    * Website to make these photo albums: https://www.publicalbum.org/blog/embedding-google-photos-albums
    *
    *  <div class="carousel">
    *   <div class="pa-carousel-widget">
    *     <object data="https://example.com/image1.jpg"></object>
    *   </div>
    *   <div class="slider_wrapper">
    *     <div class="slider"></div>
    *   </div>
    *   <div class="btns"><button class="prev">&lt;</button><button class="next">&gt;</button></div>
    *   <div class="thumbnails"></div>
    *  </div>
    *
    * */

    const allCarousels = document.querySelectorAll('.carousel');

    allCarousels.forEach((carousel)=> {
        setupCarousel(carousel);
    });

    function setupCarousel (mainCarousel) {
        const objects = mainCarousel.querySelectorAll( '.pa-carousel-widget object' );
        const defaultImageCount = 10;
        let currentIndex = 0;
        let areAllImagesLoaded = false;

        // Functions
        function loadImages ( max = defaultImageCount ) {
            const thumbnailContainer = mainCarousel.querySelector( '.thumbnails' );
            const slider = mainCarousel.querySelector( '.slider' );
            const thumbnails = mainCarousel.querySelector( '.thumbnails' );
            areAllImagesLoaded = max === -1;

            thumbnailContainer.innerHTML = '';
            slider.innerHTML = "";
            thumbnails.innerHTML = "";

            mainCarousel.classList.add( "py-4" );

            objects.forEach( ( image, index ) => {
                if ( max !== -1 && index >= max ) return;

                // Add images to the slider
                const img = document.createElement( 'img' );
                img.src = image.data;
                img.alt = `Image ${ index + 1 }`;
                img.classList.add( 'slide' );

                if ( index === 0 ){
                    img.classList.add( 'active-slide' );
                }

                mainCarousel.querySelector( '.slider' ).appendChild( img );

                // Add thumbnails
                const thumbnail = document.createElement( 'img' );
                thumbnail.src = image.data;
                thumbnail.alt = `Thumbnail ${ index + 1 }`;
                thumbnail.classList.add( 'thumbnail' );

                if ( index === 0 ) {
                    thumbnail.classList.add( 'active' );
                }

                // Add load more overlay to last thumbnail
                if (index === max - 1) {
                    const thumbnailContainer = mainCarousel.querySelector('.thumbnails');
                    const loadMoreButton = document.createElement('button');
                    const loadMoreImage = document.createElement('img');
                    const loadMoreOverlay = document.createElement('div');
                    const loadMoreOverlayContent = document.createElement('span');

                    loadMoreOverlay.appendChild(loadMoreOverlayContent);
                    loadMoreButton.appendChild(loadMoreImage);
                    loadMoreButton.appendChild(loadMoreOverlay);

                    loadMoreButton.classList.add('thumbnail', 'load_more');
                    loadMoreImage.src = objects[index].data;
                    loadMoreImage.alt = `Load more`;

                    loadMoreOverlay.classList.add('overlay');
                    loadMoreOverlayContent.textContent = '• • •';

                    thumbnailContainer.appendChild(loadMoreButton);
                } else {
                    mainCarousel.querySelector('.thumbnails').appendChild(thumbnail);
                }

                thumbnail.addEventListener('click', () => {
                    currentIndex = index;
                    navigateToImage();
                });
            } );
        }

        function shouldLoadMoreImages() {
            const thumbnails = mainCarousel.querySelectorAll( '.thumbnail' );
            const isLastThumbnail = currentIndex === (thumbnails.length - 1);
            return !areAllImagesLoaded && isLastThumbnail;
        }

        function updateThumbnails() {
            const thumbnails = mainCarousel.querySelectorAll( '.thumbnail' );
            thumbnails.forEach(thumb => thumb.classList.remove('active'));
            thumbnails[currentIndex].classList.add('active');
        }

        function updateCarouselImage() {
            mainCarousel.querySelector( '.slider' ).style.transform = `translateX(-${
                currentIndex * 100
            }%)`;
        }

        function navigateToImage() {
            if (shouldLoadMoreImages()) loadImages(-1);
            updateThumbnails();

            const allSlides = mainCarousel.querySelectorAll( '.slide' );
            allSlides.forEach( (slide) => slide.classList.remove( 'active-slide' ) );
            allSlides[currentIndex].classList.add( 'active-slide' );

            let slideHeight = allSlides[currentIndex].offsetHeight;
            if (slideHeight > 900) {
                allSlides[currentIndex].classList.add('slide--height-900');
            }

            updateCarouselImage();
        }

        // Execute setup
        loadImages();

        // Add event listeners to the next and prev buttons
        mainCarousel.querySelector('.next').addEventListener('click', () => {
            currentIndex = (currentIndex + 1) % objects.length;
            navigateToImage();
        });

        mainCarousel.querySelector('.prev').addEventListener('click', () => {
            const thumbnails = mainCarousel.querySelectorAll('.thumbnail');
            currentIndex = (currentIndex - 1) % objects.length;
            currentIndex = currentIndex === -1 ? thumbnails.length - 1 : currentIndex;
            navigateToImage();
        });

        // Add event listeners to the thumbnails
        mainCarousel.querySelectorAll('.thumbnail').forEach((thumbnail, index) => {
            thumbnail.addEventListener('click', () => {
                currentIndex = index;
                navigateToImage();
            });
        });
    }
});
